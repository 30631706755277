<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap pa-3>
      <v-flex xs9 text-left>
        <span style="font-family: poppinsbold; font-size: 20px"
          >Reshedule Details</span
        >
      </v-flex>
         </v-layout>
<v-layout wrap>
  <v-flex xs12 sm6 md3 pa-5>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                @input="menu1 = false"
              
              >
              
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm6 md3 lg3 xl4 pa-5>
            <v-select
              v-model="programId"
              :items="programs"
              item-text="progName"
              item-value="_id"
              outlined
              dense
              label="Program"
            ></v-select>
          </v-flex>
</v-layout>
         <v-layout wrap>
          <v-flex xs12>
            <template v-for="(item, i) in array">
            <v-flex pa-5 xs12 xl11 :key="item._id" pr-lg-6 pr-xl-0>
              <v-layout wrap>
                <v-flex xs12 sm6 md4 lg1 xl3>
                  <v-layout wrap>
                    <v-flex text-left xs12>
                      <span class="getText"   style="font-weight:bolder"> Ticket No  </span>
                    </v-flex>
                    <v-flex text-left xs12 v-if="item.ticket">
                      <span class="getValue">
                        <a
                        target="_blank"
                        :href="
                          'https://api.wayanadwildlifesanctuary.com/booking/ticketpdf/?id=' +
                          item._id
                        "
                        style="color: #68d389"
                      >
                        <b> #{{ item.ticket.ticketNo }}</b>
                      </a>
                      </span>
                    </v-flex>
                    <v-flex text-left xs12 v-else>
                      <span class="getValue"> Nil </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6 md5 lg2 xl3>
                  <v-layout wrap>
                    <v-flex text-left xs12>
                      <span class="getText" style="font-weight:bolder"> Program </span>
                    </v-flex>
                    <v-flex text-left xs12 v-if="item.programme.progName">
                      <span class="getValue">
                        {{ item.programme.progName }}
                      </span>
                    </v-flex>
                    <v-flex text-left xs12 v-else>
                      <span class="getValue"> Nil </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6 md3 lg1 xl3>
                  <v-layout wrap fill-height>
                    <v-flex xs12 sm12 text-left>
                      <span class="getText" style="font-weight:bolder"> Updated<br /> By</span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm12
                      text-left
                      align-self-start
                      v-if="item.isAdminUpdated == true"
                    >
                      <span class="getValue">
                      Admin
                      </span>
                    </v-flex>
                    <v-flex xs12 sm12 text-left align-self-start v-else>
                      <span class="getValue"> User </span>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs12 sm6 md3 lg2 xl3>
                  <v-layout wrap fill-height>
                    <v-flex xs12 sm12 text-left>
                      <span style="font-weight:bolder" class="getText">  Additional Amount<br /> Paid (Rs .)</span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm12
                      text-left
                      align-self-start
                      v-if="item.additionalPaymentAmount"
                    >
                      <span class="getValue">
                    {{item.additionalPaymentAmount}}
                      </span>
                    </v-flex>
                    <v-flex xs12 sm12 text-left align-self-start v-else>
                      <span class="getValue"> Nil </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6 md3 lg2 xl3>
                  <v-layout wrap fill-height>
                    <v-flex xs12 sm12 text-left>
                      <span  class="getText" style="font-weight:bolder">  Payment Gateway <br />Charge(Rs .)</span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm12
                      text-left
                      align-self-start
                      v-if="item.paymentGatewayCharge"
                    >
                      <span class="getValue">
                      {{item.paymentGatewayCharge}}
                      </span>
                    </v-flex>
                    <v-flex xs12 sm12 text-left align-self-start v-else>
                      <span class="getValue"> Nil </span>
                    </v-flex>
                  </v-layout>
                </v-flex>


                <v-flex xs12 sm6 md3 lg2 xl3>
                  <v-layout wrap fill-height>
                    <v-flex xs12 sm12 text-left>
                      <span class="getText" style="font-weight:bolder">  Old Booking Date</span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm12
                      text-left
                      align-self-start
                      v-if="item.bookingDate"
                    >
                      <span class="getValue">
                        {{
                                formatDate(item.bookingDate)
                              }}
                      </span>
                    </v-flex>
                    <v-flex xs12 sm12 text-left align-self-start v-else>
                      <span class="getValue"> Nil </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6 md3 lg2 xl3>
                  <v-layout wrap fill-height>
                    <v-flex xs12 sm12 text-left >
                      <span class="getText" style="font-weight:bolder">  New Booking Date</span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm12
                      text-left
                      align-self-start
                      v-if="item.bookingDate"
                    >
                      <span class="getValue">
                        {{
                                formatDate(item.newBookingDate)
                              }}
                      </span>
                    </v-flex>
                    <v-flex xs12 sm12 text-left align-self-start v-else>
                      <span class="getValue"> Nil </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 xl11 :key="i">
              <v-divider></v-divider>
            </v-flex>
          </template>



          </v-flex>
          <v-layout wrap justify-center>
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#68D389"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
         </v-layout>
  </div>
</template>
<script>
import axios from "axios";
// import PurchaseDetails from "./purchaseDetails";
// import PurchaseTable from "./purchaseTable";
export default {
  components: {
    // PurchaseDetails,
    // PurchaseTable
  },
  data() {
    return {
      appLoading: false,
      currentPage: 1,
      pages:1,

      panel: [],
      date: new Date(),
      array:[],
      programs: [],
      programId:null,
      disabled: false,
      ServerError: false,
      fromDate: null,
      toDate: null,
      menu1: false,
      menu2: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      tabValue: 0,
      header: "Pending",
      titleArray: [
        { title: "PENDING", value: "Pending" },
        { title: "SHIPPED", value: "Shipped" },
        { title: "DELIVERED", value: "Delivered" },
        { title: "CANCELLED", value: "Cancelled" },
        { title: "REPORTS", value: "Purchase" },

        // { title: "COTTAGES", value: "cottage" },
      ],
      purchaseData: [],
    };
  },

  beforeMount() {
    if (this.$route.query.dateProp != "dateWisePrograms") this.date = null;
    // this.getData();
    this.getProgramme();
    // this.getExcel();
    if (this.date) {
      this.date.setDate(this.date.getDate() + 1);
      this.date = this.date.toISOString().substr(0, 10);
    }
  },
  

  computed: {
    appUser() {
      return this.$store.state.userData;
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    date() {
      this.getData();
    
    },
    programId() {
    
      this.getData();

    },
 
  },
  
  methods: {
    winStepper(windowData) {
      if (windowData.getData) {
        this.currentPage=windowData.currentPage
        this.pages= windowData.pages
        this.getData();
        }
    },
    getProgramme() {
      // this.appLoading = true;
      axios({
        url: "/programme/getlist",
        method: "GET",
        params: { programmestatus: "started" },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            // this.appLoading = false;
            var temp = [
              {
                name: null,
                value: null,
              },
            ];
            temp = response.data.data;
            for (var i = 0; i < temp.length; i++) {
              temp[i].name = temp[i].progName;
              temp[i].value = temp[i]._id;
            }
            this.programs = temp;
            this.programs.unshift({ name: "All Programs", value: null });
          }
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      // var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
  
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/admin/reschedule/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          date: this.date,
count: 12,
          page: this.currentPage,
          programId:this.programId,
       
          
        },
      
      })
        .then((response) => {
          this.array = response.data.data;

          this.appLoading = false;
          this.purchaseData = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>